body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.number-slide1 {
  background: rgb(64, 175, 255);
  background: linear-gradient(128deg,
      rgba(64, 175, 255, 1) 0%,
      rgba(63, 97, 255, 1) 100%);
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(128deg,
      rgba(255, 154, 63, 1) 0%,
      rgba(255, 75, 64, 1) 100%);
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(128deg,
      rgba(182, 255, 64, 1) 0%,
      rgba(63, 255, 71, 1) 100%);
  background: linear-gradient(128deg,
      rgba(189, 255, 83, 1) 0%,
      rgba(43, 250, 82, 1) 100%);
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(128deg,
      rgba(64, 255, 242, 1) 0%,
      rgba(63, 188, 255, 1) 100%);
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(128deg,
      rgba(255, 64, 156, 1) 0%,
      rgba(255, 63, 63, 1) 100%);
}

.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(128deg,
      rgba(64, 76, 255, 1) 0%,
      rgba(174, 63, 255, 1) 100%);
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #f5c34b;
  cursor: pointer;
  background-color: #1a3760;
  border-radius: 50px;
  padding: 15px;
  transition-duration: 0.15s;
}


.arrow:not(.arrow--disabled):hover {
  padding-left: 20px;
  padding-right: 10px;
}

.arrow--left {
  left: 0px;
}

.arrow--left:not(.arrow--disabled):hover {
  padding-right: 20px;
  padding-left: 10px;
}


.arrow--right {
  left: auto;
  right: 0px;
}

.arrow--disabled {
  fill: #fddc8e;
  background-color: #506a8f;
}

#AvisClient .dots {
  display: none;
}

.popular_listing_sliders .dots {
  display: none;
}

.swiper {}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  background-color: #1a3760;
  color: #f5c34b;

  width: 42px;
  height: 42px;
  border-radius: 100%;
  transition-duration: 0.15s;
}

.swiper .swiper-button-next {
  transform: translateX(-30px);
}

.swiper .swiper-button-prev {
  transform: translateX(30px);
}

.swiper .swiper-button-next:hover {
  transform: translateX(-30px) scale(1.1);
}

.swiper .swiper-button-prev:hover {
  transform: translateX(30px) scale(1.1);
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {

  font-size: 22px;
}

.swiper .swiper-slide .image-car {
  height: 220px;
}
